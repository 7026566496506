<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">{{is_fourteen?'14th MONTH PAY'
            : '13th MONTH PAY'}}</h4>
          </v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md6>
            <v-select
              v-if="category_id===0"
              class="mx-2"
              v-model="category"
              dense
              outlined
              label="Category"
              :items="category_items"
              item-text="category"
              item-value="id"
              :rules="rules.combobox_rule"
              @change="check_availability"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mx-2"
              v-model="released_no"
              outlined
              dense
              label="Released #"
              :items="['1st Semi-Annual','2nd Semi-Annual']"
              :rules="rules.combobox_rule"
              @change="check_availability"
            ></v-select>
          </v-flex>
          <v-flex xs12 md3>
            <v-select
              class="mx-2"
              v-model="remarks"
              dense
              outlined
              label="Status"
              :items="['Active','Temporary Deactivate','Permanent Deactivate']"
              :rules="rules.combobox_rule"
              @change="check_availability"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="month_of"
              dense
              outlined
              label="Year of"
              :items="month_of_items"
              item-text="year"
              item-value="year"
              :rules="rules.combobox_rule"
              @change="selected_month"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="bank"
              dense
              outlined
              label="Bank"
              :items="bank_items"
              item-text="bank_type"
              item-value="bank_type"
              :rules="rules.combobox_rule"
              @change="selected_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4>
            <v-select
              class="mx-2"
              v-model="batch"
              dense
              outlined
              label="Batch #"
              :items="batch_items"
              item-text="batch_no"
              item-value="batch_no"
              :rules="rules.combobox_rule"
              @change="selected_batch"
            ></v-select>
          </v-flex>
          <v-flex xs12 md4 v-if="remarks!='Active'">
            <v-select
              class="mx-2"
              v-model="name"
              dense
              outlined
              label="Name"
              :items="name_items"
              item-text="name"
              item-value="id"
              :rules="rules.combobox_rule"
              @change="selected_name"
            ></v-select>
          </v-flex>


          <v-flex xs12 md4>
            <v-text-field v-model="total_amount"
                          class="mx-2"
                          dense
                          outlined
                          label="Total Amount"
                          readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="description"
              dense
              label="Description"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn
              class="w-full"
              color="primary"
              @click="done_request"
              v-if="!saving_request"
            >
              Done
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>

  import {mdiAlertOutline} from "@mdi/js";
  import {mapActions, mapGetters} from "vuex";

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      description: '',
      categiry: '',
      released_no: '',
      category: '',
      specify: '',
      month_of: '',
      category_items: [],
      month_of_items: [],
      remarks: '',
      bank: '',
      bank_items: [],
      batch: '',
      batch_items: [],
      name: '',
      name_items: [],
      total_amount: '',
      total_amount_raw: '',
    }
  }
  export default {
    props: {
      category_id: Number,
      is_fourteen: Boolean,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState();
    },
    mounted() {
      this.list_of_category_have_thirten_month_pay()
        .then(response => {
          this.category_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.category = this.category_id
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      ...mapActions('category', ['list_of_category_have_thirten_month_pay']),
      ...mapActions('thirten_month_pay', ['check_availability_thirten_month_for_request']),
      ...mapActions('fourteen_month_pay', ['check_availability_fourteen_month_for_request']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      check_availability() {
        var index = this.category_items.map(function (x) {
          return x.id;
        }).indexOf(this.category)
        if (index != -1) {
          this.categiry = this.category_items[index].category
        }
        const data = new FormData()
        data.append('category_id', this.category);
        data.append('remarks', this.remarks);
        data.append('released_no', this.released_no === '1st Semi-Annual' ? 1 : 2);
        if (this.is_fourteen) {
          this.check_availability_fourteen_month_for_request(data)
            .then(response => {
              this.month_of_items = response.data
              this.month_of = ''
              this.batch = ''
              this.batch_items = []
              this.bank_items = []
              this.total_amount = ''
              this.total_amount_raw = ''
              this.name = ''
              this.name_items = []
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.check_availability_thirten_month_for_request(data)
            .then(response => {
              this.month_of_items = response.data
              this.month_of = ''
              this.batch = ''
              this.batch_items = []
              this.bank_items = []
              this.total_amount = ''
              this.total_amount_raw = ''
              this.name = ''
              this.name_items = []
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_month(value) {
        var index = this.month_of_items.map(function (x) {
          return x.year;
        }).indexOf(value)
        if (index != -1) {
          this.bank_items = this.month_of_items[index].data_bank
          this.total_amount = ''
          this.total_amount_raw = ''
          this.name = ''
          this.name_items = []
          this.batch_items = []
        }
      },
      selected_bank(value) {
        var index = this.bank_items.map(function (x) {
          return x.bank_type;
        }).indexOf(value)
        if (index != -1) {
          this.batch_items = this.bank_items[index].data_batch
          this.batch = ''
          this.total_amount = ''
          this.total_amount_raw = ''
          this.name = ''
          this.name_items = []
        }
      },
      selected_batch(value) {
        var index = this.batch_items.map(function (x) {
          return x.batch_no;
        }).indexOf(value)
        if (index != -1) {
          if (this.remarks === 'Active') {
            this.total_amount = this.formatPrice(this.batch_items[index].amount)
            this.total_amount_raw = this.batch_items[index].amount
          } else {
            this.name = ''
            this.name_items = this.batch_items[index].names
            this.total_amount = ''
            this.total_amount_raw = ''
          }
        }
      },
      selected_name(value) {
        var index = this.name_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.total_amount = this.formatPrice(this.name_items[index].total_amount)
          this.total_amount_raw = this.name_items[index].total_amount
        }
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var add_tex = this.is_fourteen ? ' # No.-' + (this.released_no === '1st Semi-Annual' ? 1 : 2) : ''
          if (this.remarks != 'Active') {
            this.$emit('data', 'ID-' + this.category + ' # ' + this.categiry + ' # Year-' + this.month_of + ' # Data-' + this.remarks + ' # Batch-' + this.batch + ' # Bank-' + this.bank + add_tex + ' # Emp-' + this.name + ' # Name-' + this.name_items
              [this.name_items.map(function (x) {
              return x.id;
            }).indexOf(this.name)].name + ' # Desc.-' + this.description)
          } else {
            this.$emit('data', 'ID-' + this.category + ' # ' + this.categiry + ' # Year-' + this.month_of + ' # Data-' + this.remarks + ' # Batch-' + this.batch + ' # Bank-' + this.bank + add_tex + ' # Desc.-' + this.description)
          }

          this.$emit('amount', this.total_amount_raw)
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    }
  }
</script>
